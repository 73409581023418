import { format } from "date-fns"
import { utcToZonedTime } from 'date-fns-tz';

export const formatDateTime = (date = new Date()) =>
  `${format(date, "MMM  d, yyyy")} at ${format(date, "h:mm a")}`

export const titleCase = (string, splitBy = ' ') => {
  if (!string) return '';
  const fullstring = string.replace('null', '').trim();
  const words = fullstring.split(splitBy).filter(Boolean);
  // eslint-disable-next-line no-unsafe-optional-chaining
  return words.map(word => word[0]?.toUpperCase() + word.substring(1)).join(' ');
};

export const customFormatDate = (date = new Date(), formatType = 'MMM d, yyyy') => `${format(date, formatType)}`;

// Function to get the current time in a specified timezone
export const getCurrentTimeInTimezone = async (timezone) => {
  const now = new Date();
  const zonedTime = utcToZonedTime(now, timezone);
  return format(zonedTime, 'yyyy-MM-dd HH:mm:ss', { timeZone: timezone });
};

export const getBlobFromUrl = async (blobUrl) => {
  try {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error fetching blob', error);
    return null;
  }
};

export const convertBlobToFile = (blob, filename) => {
  const file = new File([blob], filename, { type: blob.type });
  return file;
};

export const handleConvertToAudioFile = async (audioUrl) => {
  if (!audioUrl) return;

  const blob = await getBlobFromUrl(audioUrl);
  if (blob) {
    const audioFile = convertBlobToFile(blob, 'recording.wav');
    return audioFile;
  }
};

const mimeCategoryMap = {
  image: 'image',
  video: 'video',
  audio: 'audio',
  application: {
    'pdf': 'pdf',
    'msword': 'document',
    'vnd.openxmlformats-officedocument.wordprocessingml.document': 'document',
    'vnd.ms-excel': 'document',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'document',
    'vnd.ms-powerpoint': 'document',
    'vnd.openxmlformats-officedocument.presentationml.presentation': 'document',
  },
  text: 'text',
};

export function getFileType(mimeType) {
  const [type, subtype] = mimeType.split('/');

  if (mimeCategoryMap[type]) {
    if (typeof mimeCategoryMap[type] === 'string') {
      return mimeCategoryMap[type];
    } else if (mimeCategoryMap[type][subtype]) {
      return mimeCategoryMap[type][subtype];
    }
  }

  return 'unknown'; // Default to 'unknown' if the MIME type doesn't match any category
}