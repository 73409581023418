import axios from "axios"
import { cloneDeep } from "lodash"
import store from "../store"
import { logoutUser } from "../store/actions"
import { showLoading, hideLoading } from "react-redux-loading-bar"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { getUserCookie } from "./storage"

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-token": process.env.REACT_APP_API_TOKEN,
  },
})

axiosApi.interceptors.request.use(
  async config => {
    store.dispatch(showLoading())

    const clonedConfig = cloneDeep(config)
    const token = authHeader()
    if (token) {
      clonedConfig.headers.common = {
        Authorization: `Bearer ${token}`,
      }
    }

    return clonedConfig
  },
  error => {
    store.dispatch(hideLoading())
    let msg = error.toString()
    toastr.error(msg)
    return Promise.reject(msg)
  }
)

axiosApi.interceptors.response.use(
  response => {
    store.dispatch(hideLoading())
    if (response.data.message) {
      toastr.success(response.data.message)
    }
    if (response?.config?.responseType === 'blob') {
      return response;
    }
    return response.data
  },
  error => {
    store.dispatch(hideLoading())

    if (
      error.response.status == 401 ||
      error.response.status == 403 ||
      error.response.status == 500
    ) {
      store.dispatch(logoutUser())
    }

    let msg = error?.response?.data?.message.toString() || error.toString()
    toastr.error(msg)
    return Promise.reject(msg)
  }
)

export const setCustomConfig = config => {
  if ('responseType' in config && config.responseType) {
    axiosApi.defaults.responseType = config.responseType;
  } else {
    delete axiosApi.defaults.responseType;
  }
};

export async function get(url, params = {}, config = {}) {
  setCustomConfig(config);
  return await axiosApi
    .get(url, { params: params }, { ...config })
    .then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, data, config = {}) {
  return await axiosApi
    .delete(url, { data: data }, { ...config })
    .then(response => response.data)
}

function authHeader() {
  const obj = getUserCookie();
  if (obj && obj.authToken) {
    return obj.authToken
  } else {
    return false
  }
}

export async function postFormData(url, data, config = {}) {
  return axiosApi.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "x-token": process.env.REACT_APP_API_TOKEN,
    },
  })
}
