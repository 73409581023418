import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { useHistory } from "react-router-dom";
import 'assets/scss/custom/pages/_tutorials.scss'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { nanoid } from "nanoid"
import { Button, Dropdown, DropdownItem, DropdownMenu, Input, Spinner } from "reactstrap";
import Select from "react-select"
import ImageEditMenu from "./components/ImageEditMenu";
import VideoComponent from "./components/VideoComponent";
import TextEditMenu from "./components/TextEditMenu";
import { titleCase } from "helpers/common_helper";
import { createUpdateTutorials, getTutorials, getTutorialsCategory } from "helpers/tutorials_helper";
import { deepCopy } from "utility/common_helper";

const CreateTutorial = props => {
  const history = useHistory();
  const isEdit = props.match.params.tutorialID;
  document.title = isEdit ? 'Edit' : 'Create' + " Tutorial | " + process.env.REACT_APP_NAME

  const [countries] = useState([{
    label: "",
    value: ""
  }, {
    label: "Canada",
    value: "CA"
  }, {
    label: "US",
    value: "US"
  }])

  const [accountTypes] = useState([{
    label: "All",
    value: "all"
  }, {
    label: "Association",
    value: "association"
  }, {
    label: "Agent",
    value: "agent"
  }])


  const [isLoading, setIsLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [menu, setMenu] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  
  const [tutorial, setTutorial] = useState({
    tutorial_id: null,
    title: null,
    status: 'visible',
    category: null,
    account_type: accountTypes[0],
    country: '',
    content: [],
  });

  const fetchCategoriesList = async () => {
    getTutorialsCategory().then(response => {
      setCategories(response);
    });
  }

  useEffect(() => {
    fetchCategoriesList();
  }, [])

  useEffect(() => {
    if (props.match.params.tutorialID) {

      setIsLoading(true);

      getTutorials({ uid: props.match.params.tutorialID }).then(response => {
        const obj = {
          tutorial_id: response[0].id,
          title: titleCase(response[0].title),
          status: response[0].status,
          category: {
            ...response[0].category,
            label: titleCase(response[0].category.name),
            value: response[0].category.id
          },
          account_type: response[0].account_type ? accountTypes.find(a => a.value === response[0].account_type) : null,
          country: response[0].country ? countries.find(a => a.value === response[0].country) : null,
          content: response[0].content,
        }

        setTutorial(obj)
        setIsLoading(false);
      });
    }
  }, [props.match.params.tutorialID]);

  function updateTutorial(key, val) {
    if (key === 'content') {
      setTutorial({
        ...tutorial,
        content: [...(tutorial.content || []), {
          type: val,
          id: nanoid(),
          styles: {
            objectFit: val === 'image' ? 'contain' : '',
            height: val === 'video' || val === 'image' ? 300 : ''
          }
        }]
      })
    } else {
      setTutorial({
        ...tutorial,
        [key]: val
      })
    }
  }

  const updateContent = (id, key, val) => {
    const tmpData = tutorial.content;
    const tmpInd = tmpData.findIndex(d => d.id === id)

    tmpData[tmpInd] = {
      ...tmpData[tmpInd],
      [key]: val
    }

    setTutorial({ ...tutorial, content: tmpData })
  }

  const deleteContent = (id) => {
    const tmpData = tutorial.content.filter(c => c.id !== id);

    setTutorial({ ...tutorial, content: tmpData })
  }

  const changeImage = (e, content) => {

    if (e.target.files?.length) {
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        async () => {
          const fileData = e.target.files[0];

          updateContent(content.id, 'src', {
            src: reader.result,
            filename: fileData.name,
            type: fileData.type,
            size: fileData.size,
            fileData,
          });
        },
        false,
      );

      reader.readAsDataURL(e.target.files[0]);
    } else {
      updateContent(content.id, 'src', null);
    }
  };

  async function createTutorial(e) {
    e.preventDefault();
    e.stopPropagation();

    const data = await deepCopy(tutorial);

    setIsLoading(true);
    const formData = {
      tutorial_id: data.tutorial_id,
      title: data.title,
      status: data.status,
      category_id: data.category?.value || null,
      account_type: data.account_type?.value,
      country: data.country?.value || null,
      content: data.content,
    };

    for (const iterator of formData.content) {
      if (iterator?.src?.fileData) {
        iterator.src.src = null;
      }
    }

    createUpdateTutorials({
      payload: JSON.stringify(formData),
      content: formData.content
    }).then(() => {
      // const { uid } = response;
      // history.push('/tutorials')
      setIsLoading(false);
    }, error => {
      setIsLoading(false);
    });

  }

  return (
    <div className="page-content">
      {isLoading ? <div style={{width: '100%', height: 600,  display: 'flex', justifyContent: 'center',alignItems: 'center'}}><Spinner size="lg" style={{height: 45, width: 45, borderWidth: 5}}  /></div> : <><div className="d-flex justify-content-between mb-2">
        <div className="d-flex align-items-center" style={{ gap: 8 }}>
          <p className="m-0" onClick={() => history.push('/tutorials')} style={{ cursor: 'pointer', color: '#495057', alignItems: 'center', textTransform: 'uppercase', fontSize: 19, fontWeight: 600 }}>Tutorials</p>
          <p className="m-0 ab-icons">chevron_right</p>
          {isEdit ? <><p className="m-0" style={{ fontSize: 17, cursor: 'pointer' }} onClick={() => history.push(`/tutorials/${tutorial?.category?.slug}`)}><span
              className="iconpicker-icons me-2" style={{ fontSize: 17, color: tutorial?.category?.color }}>
              {tutorial?.category?.icon}
            </span>{tutorial?.category?.label}</p>
          <p className="m-0 ab-icons">chevron_right</p></> : null}
          
          <p className="m-0" style={{ fontSize: 17 }}>{isEdit ? 'Update' : 'Create'} Tutorial</p>
        </div>
      </div>
      <div className="create-tutorial-container">
        <form onSubmit={createTutorial}>
          <div className="d-flex flex-nowrap" style={{ gap: 15 }}>
            <div className="w-100">
              <p className="mb-1">Title</p>
              <Input style={{ borderRadius: 0 }} value={tutorial.title} onChange={(e) => updateTutorial('title', e.target.value)} name="title" required placeholder="Enter Title" className="mb-3 w-100" />
            </div>
            <div className="w-100">
              <p className="mb-1">Status</p>
              <div className="d-flex">
                <button type="button" onClick={() => {
                  updateTutorial('status', 'visible')
                }} className="toggle-button-left" style={{ background: tutorial.status !== 'hidden' ? 'var(--bs-primary)' : '#fff', color: tutorial.status === 'hidden' ? 'var(--bs-primary)' : '#fff' }}>Visible</button>
                <button type="button" onClick={() => {
                  updateTutorial('status', 'hidden')
                }} className="toggle-button-right" style={{ background: tutorial.status === 'hidden' ? 'var(--bs-primary)' : '#fff', color: tutorial.status !== 'hidden' ? 'var(--bs-primary)' : '#fff' }}>Hidden</button>
              </div>
            </div>
          </div>
          <p className="mb-1">Category</p>
          <Select
            required
            id="category"
            name="category"
            isMulti={false}
            isSearchable
            onChange={selectedOption => {
              updateTutorial('category', selectedOption)
            }}
            styles={{
              option: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                fontWeight: 400,
                color: '#444',
                background: state.isSelected || state.isFocused ? 'rgba(0, 0, 0, 0.05)' : 'inherit',
              })
            }}
            formatOptionLabel={(opt) => <div>
              <div style={{ gap: 12 }} className="d-flex align-items-start">
                <span
                  className="iconpicker-icons" style={{ fontSize: 16, marginTop: 2, color: opt.color }}>
                  {opt.icon}
                </span>
                <div>
                  <p className="m-0">
                    {titleCase(opt.name)}
                  </p>
                </div>
              </div>
            </div>}
            value={tutorial.category || null}
            options={(categories || []).map(c => ({
              ...c,
              label: titleCase(c.name),
              value: c.id
            }))}
            className="w-100 category-select"
          />

          <p className="mb-1 mt-3">Account type</p>
          <Select
            id="account_type"
            name="account_type"
            isMulti={false}
            isSearchable
            onChange={selectedOption => {
              updateTutorial('account_type', selectedOption)
            }}
            value={tutorial.account_type || null}
            options={(accountTypes || []).map(c => ({
              ...c,
            }))}
            className="w-100 category-select"
            required
          />
          <p className="mb-1 mt-3">Country</p>
          <Select
            id="country"
            name="country"
            isMulti={false}
            isSearchable
            onChange={selectedOption => {
              updateTutorial('country', selectedOption)
            }}
            value={tutorial.country || null}
            options={(countries || []).map(c => ({
              ...c,
            }))}
            className="w-100 category-select"
            required
          />

          <p className="mt-3 mb-3">Content</p>


          {tutorial.content?.length ? <div style={{ margin: '5px 0px 30px 0px', background: 'rgba(0, 0, 0, 0.02)', padding: 20, borderRadius: 5 }}>{tutorial.content?.map((content, index) => <div className="mb-3" key={index}>
            {content.type === 'text' ?
              <div className="text-content-container">
                <TextEditMenu deleteText={() => deleteContent(content.id)} />
                <div className={isTextExpanded? 'expanded-text-editor' : ''} style={{position: 'relative' }}>
                  
                  {isTextExpanded ?  <button type="button" onClick={() => {
                    setIsTextExpanded(false)
                  }} className="ab-icons" style={{color: '#444', background: 'none', outline: 'none', border: 'none', position: 'absolute', right: 65, top:65, zIndex: 9999}}>shrink_arrows</button> : <button type="button" onClick={() => {
                    setIsTextExpanded(true)
                  }} className="ab-icons" style={{color: '#444', background: 'none', outline: 'none', border: 'none', position: 'absolute', right: 14, top: 14, zIndex: 3}}>expand_arrows</button>}
                <ReactQuill theme="snow" value={content.text || ''} onChange={(e) => {
                  updateContent(content.id, 'text', e)
                }} />
                </div>
              </div>
              : null
            }
            {content.type === 'image' ? <div className="uploaded-image-container">
              <div className="image-edit-menu-button" style={{ opacity: isMenuOpen === content.id ? 1 : '' }}>
                <ImageEditMenu
                  styles={content.styles || {}}
                  setStyles={(data) => {
                    updateContent(content.id, 'styles', data)
                  }}
                  hideEdit={!(content.src?.src || content.src?.url)}
                  deleteImage={() => deleteContent(content.id)}
                  setImageMenu={(status) => setIsMenuOpen(status ? content.id : false)} />
              </div>
              {(content.src?.src || content.src?.url) ? <div className="d-flex justify-content-center w-100">
                <img className="uploaded-image" style={{ objectFit: content.styles?.objectFit || 'contain', height: Number(content.styles?.height || 300) }} src={(content.src?.src || content.src?.url)} alt="content" />
              </div> :
                <div>
                  <input onChange={(e) => changeImage(e, content)} className="d-none" id={content.id} type="file" accept="image/*" />
                  <button className="upload-image-button" type="button" onClick={() => {
                    document.getElementById(content.id).click()
                  }}>Upload Image</button>
                </div>}
            </div> : null}
            {content.type === 'video' ?
              <VideoComponent
                updateContent={updateContent}
                deleteContent={deleteContent}
                content={content}
                addVideo={(data, isDroplr) => {
                  updateContent(content.id, isDroplr ? 'videoID' : 'url', data)
                }} /> : null}
          </div>)}</div> : null}

          <div className="d-flex w-100 justify-content-center mt-3">
            <Dropdown isOpen={menu} toggle={() => setMenu(false)} >
              <button onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setMenu(true)
              }} style={{ background: '#fff', border: '1px solid var(--bs-primary)', color: 'var(--bs-primary)', fontSize: 14, padding: '4px 10px', borderRadius: 5, boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.08)', marginTop: 10 }}> Add Content</button>
              <DropdownMenu end style={{ width: 300, padding: 0 }}>
                <DropdownItem onClick={(e) => { updateTutorial('content', 'text') }} style={{ color: '#444', padding: '12px 15px', borderBottom: '1px solid #ddd', display: 'flex', alignItems: 'center', gap: 6 }}><span className="ab-icons">edit_box</span>Text</DropdownItem>
                <DropdownItem onClick={(e) => { updateTutorial('content', 'image') }} style={{ color: '#444', padding: '12px 15px', borderBottom: '1px solid #ddd', display: 'flex', alignItems: 'center', gap: 6 }}><span className="ab-icons">image</span>Image</DropdownItem>
                <DropdownItem onClick={(e) => { updateTutorial('content', 'video') }} style={{ color: '#444', padding: '12px 15px', display: 'flex', alignItems: 'center', gap: 6 }}><span className="ab-icons">video</span>Video</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>

          <div className="d-flex w-100 justify-content-end" style={{ marginTop: 70 }}>
            <Button type="submit" color="primary" className="mt-4" disabled={isLoading}>
              {props.match.params.tutorialID ? 'Update' : 'Create'} Tutorial
            </Button>
          </div>
        </form>
      </div></>}
      
    </div>
  )
}

export default withRouter(CreateTutorial)
